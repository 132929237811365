import { AuthAxios } from "./AxiosHandler"

class LeavesSuggestionService {
    static endPoint = "leaves-suggestion"
    static getAll() {
        return AuthAxios.get(`${LeavesSuggestionService.endPoint}`)
    }
    static delete(id: number) {
        return AuthAxios.post(`${LeavesSuggestionService.endPoint}/delete/${id}`)
    }
    static show(id: number | null) {
        return AuthAxios.get(`${LeavesSuggestionService.endPoint}/find/${id}`)
    }
    static approve(id: number | null) {
        return AuthAxios.post(`${LeavesSuggestionService.endPoint}/approve/${id}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${LeavesSuggestionService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${LeavesSuggestionService.endPoint}/update`, body)
    }
}
export default LeavesSuggestionService