import i18n from "../../i18n"

import "./RealTimeNotification.css"

const RealTimeNotification = (props: any) => {
    console.log(props.data)
    return (
        <>
            <h6 className="real-time-h6">{i18n.language == "en" ? props?.data?.title_en : props?.data?.title_ar}</h6>
            <p>{i18n.language == "en" ? props?.data?.message_en : props?.data?.message_ar}</p>
        </>
    )
}

export default RealTimeNotification