import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { NotificationModal } from "../../../../Components/MainNav";

const MainNavModals = () => {
    const { t } = useTranslation()
    const modals = useMemo(() => [
        {
            id: 1,
            name: "notification",
            title: t("notification"),
            children: <NotificationModal />
        },
    ], [])

    return { modals }
}

export default MainNavModals