import { Dispatch, SetStateAction } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, handleApprove: any) => {

    const items: DropDownType[] = [
        {
            id: 1,
            children: t("show"),
            onClick: () => {
                setRecordId(id)
                handleShow("showLeavesSuggestion")
            }
        },
        {
            id: 2,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editLeavesSuggestion")
            }
        },
        {
            id: 3,
            children: t("Delete"),
            onClick: () => {
                setRecordId(id)
                handleShow("Delete")
            }
        },
        {
            id: 4,
            children: t("approve"),
            onClick: () => {
                handleApprove(id)
            }
        },
    ]

    return { items }
}

export default ActionsDropdown