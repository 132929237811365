import { useContext, useMemo } from "react"
import { TableContext } from "../../../../Context/TableContext"
import { DropDownMenu } from "../../../../Components"
import ActionsDropdown from "./ActionsDropdown"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import LeavesSuggestionService from "../../../../Services/LeavesSuggestionService"

const LeavesSuggestionCols = (t: (key: string) => string, handleShow: (name: string) => void) => {
    const { setRecordId } = useContext(TableContext)
    const [handleApprove] = useQueryPost(LeavesSuggestionService.approve, ["approve-leaves-suggestion"], {
        success: t("approved-successfully"),
    }, ["get-all-leaves-suggestion"])
    const columns = useMemo(() => {
        return [
            {
                title: t("name"),
                dataIndex: 'user',
                key: 'user',
                render: (user: any) => user?.name
            },
            {
                title: t("direct_manager"),
                dataIndex: 'approved_by_direct_manager',
                key: 'approved_by_direct_manager',
                render: (id: number) => id == 1 ? t("approved") : t("unpproved")
            },
            {
                title: t("general_manager"),
                dataIndex: 'approved_by_general_manager',
                key: 'approved_by_general_manager',
                render: (id: number) => id == 1 ? t("approved") : t("unpproved")

            },
            {
                title: t("hr"),
                dataIndex: 'approved_by_hr',
                key: 'approved_by_hr',
                render: (id: number) => id == 1 ? t("approved") : t("unpproved")
            },
            {
                title: t("start"),
                dataIndex: 'start_date',
                key: 'start_date',
            },
            {
                title: t("end"),
                dataIndex: 'end_date',
                key: 'end_date',
            },

            {
                title: t("actions"),
                dataIndex: 'id',
                key: 'id',
                render: (id: number) => <>
                    <DropDownMenu
                        children={t("actions")}
                        className="action-table"
                        arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, handleApprove).items} />
                </>
            },
        ]
    }, [])

    return { columns }
}

export default LeavesSuggestionCols