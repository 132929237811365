import "./MainLayout.css"
import { FC, useContext } from "react"
import { LinksProps } from "../../Models/LinksProps"
import { Outlet } from "react-router-dom"
import { MainNav, SideBar } from "../../Sections"
import { Loading } from "../"
import { LayoutContext } from "../../Context/LayoutContext"
import { useNotificationSetup } from "../../Custom Hooks/useNotificationSetup"
import axios from "axios"
import Cookies from "js-cookie"
type MainLayoutProps = {
    linksArray: LinksProps[]
}
const MainLayout: FC<MainLayoutProps> = ({ linksArray }) => {
    const { getIsMainLoading } = useContext(LayoutContext)
    useNotificationSetup();

    return (
        <>
            <div className="main-layout">
                {getIsMainLoading() == true ? <Loading /> : <></>}
                <div className="row p-0 m-0 row-layout">
                    <SideBar linksArray={linksArray} />
                    <div className="col-md-9 ">
                        <MainNav />
                        <Outlet />
                    </div>
                </div>
            </div>
        </>

    )
}

export default MainLayout