import "./MainPage.css"
import { ToastContainer } from "react-toastify"
import MainPageService from "../../../Services/MainPageService"
import { AdministrativeDecisions, DailyQuote, EmplyerOfTheMonth, WordFromCompany } from "../../../Sections/MainPage"
import useQueryGet from "../../../Custom Hooks/useQueryGet"


const MainPage = () => {
    const [details,] = useQueryGet(MainPageService.get, ["main-page"], undefined, {
        mainLoading: true
    })
    return (
        <>

            <div className="container-fluid main-page-con">
                <EmplyerOfTheMonth details={details != undefined ? details : undefined} />
                <div className="row mb-5 small-screen-margin-b">
                    <WordFromCompany details={details != undefined ? details : undefined} />
                    <DailyQuote details={details != undefined ? details : undefined} />
                </div>
                <AdministrativeDecisions />
            </div>
            <ToastContainer />
        </>
    )
}

export default MainPage