import { AxiosResponse } from "axios"
import Quotation from "../../../Assets/Images/Dashboard/MainPage/quotation-marks-in-speech-bubble-svgrepo-com.svg"
import "./WordFromCompany.css"
import { FC, memo } from "react"
import { useTranslation } from "react-i18next"
import { BoxWithShadow } from "../../../Components"
type WordFromCompanyProps = {
    details: AxiosResponse | undefined
}
const WordFromCompany: FC<WordFromCompanyProps> = ({ details }) => {
    const { i18n } = useTranslation()
    return (
        <div className="col-md-6 p-0 small-screen-margin-b small-screen-col">
            <BoxWithShadow additionalClass="blue flex-center-col word-from-company s">
                <img src={Quotation} alt="" />
                <p>{i18n.language == "en" ? details?.data?.data?.company_text_en : details?.data?.data?.company_text_ar}</p>
            </BoxWithShadow>
        </div>
    )
}

export default memo(WordFromCompany) 