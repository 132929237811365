import Echo from "laravel-echo";
import Cookies from "js-cookie";
import { ChannelAuthorizationOptions } from 'pusher-js';
import Pusher from "pusher-js";
const channelAuthorization: ChannelAuthorizationOptions = {
    transport: 'jsonp',
    endpoint: "https://crmapi.aetc.ae/api" + "/broadcasting/auth",
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json'
    }
};
const pusher = new Pusher('8da4a36dbda204875be3', {
    cluster: 'eu',
    channelAuthorization
});

const echo = new Echo({
    broadcaster: 'pusher',
    key: '8da4a36dbda204875be3',
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: "https://crmapi.aetc.ae/api" + "/broadcasting/auth",
    auth: {
        headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            Accept: 'application/json',
        },
    },
});
// const pusherClient = new Pusher("8da4a36dbda204875be3", {
//     cluster: "eu", // العنقود (Cluster)
//     forceTLS: false, // تأكد من استخدام TLS
//     authEndpoint: "https://crmapi.aetc.ae" + "/broadcasting/auth", // رابط التوثيق للقنوات الخاصة
//     auth: {
//         headers: {
//             Authorization: `Bearer ${Cookies.get("token")}`, // التوكن إذا كنت تستخدم التوثيق,
//         },
//     },
// });

// const echo = new Echo({
//     broadcaster: 'pusher',
//     client: pusherClient,

// })


export default echo;