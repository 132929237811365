import { useTranslation } from "react-i18next"
import "./SmallScreenSidebar.css"
import { motion } from "framer-motion"
import { FC, useContext, useState } from "react"
import { LayoutContext } from "../../../Context/LayoutContext"
import { LinksProps } from "../../../Models/LinksProps"
import Cookies from "js-cookie"
import { IoMdClose } from "react-icons/io";
import Logo from "../../../Assets/Images/AETC Logo.svg"
import { MenuItem, WelcomeBox } from "../../"

type SmallScreenSidebarProps = {
    linksArray: LinksProps[]
}
const SmallScreenSidebar: FC<SmallScreenSidebarProps> = ({ linksArray }) => {
    const { i18n } = useTranslation()
    const { getIsSideBarVisible, setterIsSideBarVisible } = useContext(LayoutContext)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.get("permissions") || ""))
    const links = linksArray.map(link => (
        <MenuItem link={link} permissions={permissions} key={link.id} />
    ))
    return (
        <motion.div className="sm-postion-con"
            animate={{
                x: i18n.language == "en" && getIsSideBarVisible() == true ? "-60px" :
                    i18n.language == "ar" && getIsSideBarVisible() == true ? "50px" :
                        i18n.language == "ar" && getIsSideBarVisible() == false ? "500px" :
                            "-500px",
                opacity: getIsSideBarVisible() == true ? "1" : 0
            }}
            transition={{
                duration: .5
            }}
        >
            <div className={`sm-screen-sidebar flex-col-between ${i18n.language == "ar" && "ar"}`}>
                <div className="close-icon" onClick={setterIsSideBarVisible}>
                    <IoMdClose className="icon" />
                </div>
                <div className="flex-start-center-col">
                    <div className="sideBar-header">
                        <div className="navbar-brand">
                            <img src={Logo} alt="" />
                        </div>
                    </div>
                    <hr className="horizontal dark mt-0" />
                    <div className="flex-center">
                        <ul className="flex-center-col">
                            {links}
                        </ul>
                    </div>
                </div>
                <WelcomeBox />
            </div>
        </motion.div>
    )
}

export default SmallScreenSidebar