import { useParams } from "react-router-dom"
import { BoxTitle, MainBtn, ModalComponent } from "../../../../Components"
import UserService from "../../../../Services/UserService"
import "./FileMangement.css"
import { useCallback, useContext, useMemo } from "react"
import { UserContext } from "../../../../Context/UserContext"
import { ToastContainer } from "react-toastify"
import { useTranslation } from "react-i18next"
import CheckExtinsion from "../../../../Helpers/CheckExtinsion"
import { FileBox } from "../../../../Components/ProfileMangement/FileMangement"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"
import useModals from "../../../../Custom Hooks/useModals"
import { FileMangementModals } from "../../../../Assets/Data/Dashboard/ProfileMangement/FileMangement"

const FileMangement = () => {
    const { t } = useTranslation()
    const { loginedUserId } = useContext(UserContext)
    const { show, handleClose, handleShow } = useModals({
        Delete: false,
        addFile: false
    })

    const params = useParams()
    const [files,] = useQueryGet(() => UserService.getFilesById(Object.keys(params).length > 0 ? params.id : loginedUserId),
        ["get-all-files", params.id], undefined, {
        mainLoading: true
    })
    const filesBox = useMemo(() => {
        return files?.data?.data?.user_files?.map((file: any) => {
            let image = CheckExtinsion(file.file_path)
            return (
                <FileBox desc={file.desc} image={image} path={file.file_path} id={file.id} handleShow={handleShow} />
            )
        })
    }, [files])
    const memoizedMainBtnFunc = useCallback(() => {
        handleShow("addFile")
    }, [])
    const memoizedHandleClose = useCallback((name: string) => {
        handleClose(name)
    }, [show])
    const { modals } = FileMangementModals(memoizedHandleClose, Object.keys(params).length > 0 ? params.id : loginedUserId, t)
    return (
        <>

            <div className="file-mangement-con">
                <div className="flex-between mb-3 mb-md-5">
                    <BoxTitle title={t("FileMangement")} />
                    <MainBtn title={t("add")} onClick={memoizedMainBtnFunc} />
                </div>
                <div className="row row-cols-3">
                    {filesBox}
                    <ModalComponent show={show} handleClose={memoizedHandleClose} arrayOfModals={modals} />
                </div>
            </div>
            <ToastContainer />

        </>

    )
}

export default FileMangement