import { useContext, useEffect } from "react";
import { UserContext } from "../Context/UserContext";
import echo from "../echo";
import { toast } from "react-toastify";
import { RealTimeNotification } from "../Components";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../Context/LayoutContext";

export const useNotificationSetup = () => {
    const { loginedUserId } = useContext(UserContext);
    const { setIsNewNotification } = useContext(LayoutContext)
    const { i18n } = useTranslation()
    useEffect(() => {
        if (loginedUserId) {
            const channel = echo.private(`gn.${loginedUserId}`);
            channel.listen(".global.notification", (data: any) => {
                if (data) {
                    setIsNewNotification(true)
                }
                toast(RealTimeNotification, {
                    position: i18n.language == "en" ? "top-right" : "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    data: data,

                });
            })
        }
    }, [loginedUserId]); // Add loginedUserId to the dependency array
};