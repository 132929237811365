import { FaInfo } from "react-icons/fa6";
import "./NotificationModal.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet";
import NotificationService from "../../../Services/NotificationService";
import { useTranslation } from "react-i18next";

const NotificationModal = () => {

    const { i18n } = useTranslation()
    const [data] = useQueryGet(NotificationService.getAll, ["get-all-notification"], undefined, {
        modalLoading: true
    })
    const notifications = data?.data?.data?.data?.map((notification: any) => {
        const notificationObject = JSON.parse(notification?.data)
        return (
            <div className="col-xs-12 col-sm-12 col-sm-offset-3" key={notification.id}>
                <div className="new-message-box">
                    <div className="new-message-box-info">
                        <div className="info-tab tip-icon-info flex-center" title="error">
                            <FaInfo className="icon" />
                        </div>
                        <div className="tip-box-info">
                            <h6>
                                {i18n.language == "en" ? notificationObject.title_en : notificationObject.title_ar}
                            </h6>
                            <p>
                                {i18n.language == "en" ? notificationObject.message_en : notificationObject.message_ar}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <div className="row">
            {notifications}
        </div>
    )
}

export default NotificationModal