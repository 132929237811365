import "./MainNav.css"
import { DropDownMenu, ModalComponent } from "../../Components"
import { IoIosNotifications } from "react-icons/io";
import { useTranslation } from "react-i18next"
import useModals from "../../Custom Hooks/useModals"
import { PageTitle, SalaryAlert } from "../../Components/MainNav"
import { DropDownData, MainNavModals } from "../../Assets/Data/Dashboard/MainNav"
import { memo, useCallback, useContext } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { LayoutContext } from "../../Context/LayoutContext";

const MainNav = () => {
    const { show, handleShow, handleClose } = useModals({
        notification: false
    })
    const { setterIsSideBarVisible, isNewNotification } = useContext(LayoutContext)
    const { i18n } = useTranslation()
    const { modals } = MainNavModals()
    const { items } = DropDownData()
    const memoizedHandelShow = useCallback(() => {
        handleShow("notification")
    }, [])
    const memoizedHandelClose = useCallback((name: string) => {
        handleClose(name)
    }, [handleClose])
    return (
        <>
            <div className={`main-dashboard-nav my-4 flex-between py-1 ${i18n.language == "ar" && "ar"}`}>
                <div className="burger-icon" onClick={setterIsSideBarVisible}>
                    <CiMenuBurger className="icon" size={22} />
                </div>
                <PageTitle />
                <div className="flex-between">
                    <div className="notification-box" onClick={memoizedHandelShow}>
                        <IoIosNotifications className="icon" size={25} />
                        {isNewNotification == true ? <div className="alert-notification"></div> : <></>}
                    </div>
                    <ModalComponent arrayOfModals={modals} show={show} handleClose={memoizedHandelClose} />
                    <DropDownMenu arrayOfItems={items} className="profile-dropdown bg-full default-img" />
                </div>
            </div>
            <SalaryAlert />
        </>

    )
}

export default memo(MainNav) 