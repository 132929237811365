import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { DropDownType } from "../../../../Models/DropDownType"
import DropDownMenu from "../../../../Components/DropDownMenu/DropDownMenu"
import SaudiFlag from "../../../Images/saudi-arabia.svg"
import UnitedStatesFlag from "../../../Images/united-states.svg"
import LangData from "./LangData"
import { Authintication } from "../../../../Helpers"

const DropDownData = () => {
    const { t, i18n } = useTranslation()

    const items: DropDownType[] = [
        {
            id: 1,
            children: <Link to="Profile-Mangement">{t("Profile-Mangement")}</Link>
        },
        {
            id: 2,
            className: "btn-group dropstart",
            children: <DropDownMenu className="p-0" arrayOfItems={LangData().items}>
                {i18n.language == "ar" ? <div className="main-languages-li">
                    <span><img src={SaudiFlag} alt="" /></span>
                    العربية

                </div> :
                    <div className="main-languages-li">
                        <span><img src={UnitedStatesFlag} alt="" /></span>
                        English

                    </div>}
            </DropDownMenu>
        },
        {
            id: 3,
            onClick: () => Authintication().SignOut(),
            children: <>{t("SignOut")}</>
        }

    ]

    return { items }
}

export default DropDownData