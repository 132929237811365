import { FC } from "preact/compat"
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState } from "react"


export const LayoutContext = createContext<{
    getIsModalLoading: () => boolean | null,
    setterIsModalLoading: (value: boolean | null) => void,
    getIsMainLoading: () => boolean | null,
    setterIsMainLoading: (value: boolean) => void,
    getIsSideBarVisible: () => boolean,
    setterIsSideBarVisible: () => void,
    isNewNotification: boolean,
    setIsNewNotification: Dispatch<SetStateAction<boolean>>
}>({
    getIsModalLoading: () => null,
    setterIsModalLoading: () => { },
    getIsMainLoading: () => true,
    setterIsMainLoading: () => { },
    getIsSideBarVisible: () => false,
    setterIsSideBarVisible: () => { },
    isNewNotification: false,
    setIsNewNotification: () => { }
})

type LayoutContextProviderType = {
    children: ReactNode
}
const LayoutContextProvider: FC<LayoutContextProviderType> = ({ children }) => {
    const [isMainLoading, setIsMainLoading] = useState<boolean>(true)
    const [isModalLoading, setIsModalLoading] = useState<boolean | null>(null)
    const [isSideBarVisible, setIsSideBarVisible] = useState<boolean>(false)
    const [isNewNotification, setIsNewNotification] = useState<boolean>(false)
    const getIsModalLoading = useCallback(() => {
        return isModalLoading
    }, [isModalLoading])

    const setterIsModalLoading = useCallback((value: boolean | null) => {
        setIsModalLoading(value)
    }, [setIsModalLoading])


    const getIsMainLoading = useCallback(() => {
        return isMainLoading
    }, [isMainLoading])

    const setterIsMainLoading = useCallback((value: boolean) => {
        setIsMainLoading(value)
    }, [setIsMainLoading])

    const getIsSideBarVisible = useCallback(() => {
        return isSideBarVisible
    }, [isSideBarVisible])

    const setterIsSideBarVisible = useCallback(() => {
        setIsSideBarVisible(!isSideBarVisible)
    }, [isSideBarVisible])



    return (
        <LayoutContext.Provider value={{
            getIsModalLoading,
            setterIsModalLoading,
            getIsMainLoading,
            setterIsMainLoading,
            getIsSideBarVisible,
            setterIsSideBarVisible,
            isNewNotification,
            setIsNewNotification
        }}>
            {children}
        </LayoutContext.Provider>
    )
}


export default LayoutContextProvider