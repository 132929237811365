import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import useModals from "../../../Custom Hooks/useModals"
import "./AdministrativeDecisions.css"
import MainPageModals from "../../../Assets/Data/Dashboard/MainPage/MainPageModals"
import { memo, useCallback, useState } from "react"
import AdministrativeDecisionsService from "../../../Services/AdministrativeDecisionsService"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import AdministrativeDecisionsCols from "../../../Assets/Data/Dashboard/MainPage/AdministrativeDecisionsCols"

const AdministrativeDecisions = () => {
    const [page, setPage] = useState(1)
    const { t } = useTranslation()
    const { show, handleShow, handleClose } = useModals(
        {
            Decisions: false,
            EditDecisions: false,
            ShowDecision: false
        }
    )
    const [data, isFetching] = useQueryGet(() => AdministrativeDecisionsService.get(page), ["administrative-decisions", page])
    const mainBtnFunc = useCallback(() => {
        memomizedHandleShow("Decisions")
    }, [])
    const memomizedHandleShow = useCallback((name: string) => {
        handleShow(name)
    }, [handleShow])
    const memomizedHandleClose = useCallback((name: string) => {
        handleClose(name)
    }, [handleClose])
    const paginationFunc = useCallback((page: number) => {
        setPage(page)
    }, [page])
    const { modals } = MainPageModals(memomizedHandleClose)
    const { columns } = AdministrativeDecisionsCols(memomizedHandleShow)
    return (
        <BoxWithShadow additionalClass="last-news small-screen-width">
            <div className="flex-between-start">
                <BoxTitle title={t("Administrativedecisions")} />
                <MainBtn title={t("add")} onClick={mainBtnFunc} />
            </div>
            <DynamicTable
                columns={columns}
                dataSource={data?.data?.data?.data} loading={isFetching}
                pagination={{
                    pageSize: data?.data?.data?.per_page,
                    total: data?.data?.data?.total,
                    onChange: (page) => paginationFunc(page)
                }}
            />
            <ModalComponent show={show} handleClose={memomizedHandleClose} arrayOfModals={modals} />
        </BoxWithShadow>
    )
}

export default memo(AdministrativeDecisions)